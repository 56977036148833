import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "新闻链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["url", {
        rules: [{
          required: true,
          message: "请输入新闻链接!"
        }]
      }],
      expression: "[\n                          'url',\n                          {\n                              rules: [\n                                  { required: true, message: '请输入新闻链接!' },\n                              ],\n                          },\n                      ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "255"
    },
    on: {
      blur: function blur($event) {
        return _vm.selectbyurl($event.target.value);
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新闻标题"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [
          //{ required: true, message: '请输入新闻标题!' },
        ]
      }],
      expression: "[\n                          'title',\n                          {\n                              rules: [\n                                  //{ required: true, message: '请输入新闻标题!' },\n                              ],\n                          },\n                      ]"
    }],
    ref: "inputTitle",
    attrs: {
      autoComplete: "off",
      disabled: true,
      size: "large",
      placeholder: "",
      maxlength: "100"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "侧边推荐位排序"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["sort", {
        rules: [
        //{ required: true, message: '请输入侧边推荐位排序' },
        {
          pattern: /\d$/,
          message: "请输入数字"
        }]
      }],
      expression: "[\n                          'sort',\n                          {\n                              rules: [\n                                  //{ required: true, message: '请输入侧边推荐位排序' },\n                                  { pattern: /\\d$/, message: '请输入数字'}\n                              ],\n                          },\n                      ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "10"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "Feed强推排序"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["recommendedsort", {
        rules: [{
          required: false,
          message: "请输入Feed强推排序"
        }, {
          pattern: /\d$/,
          message: "请输入数字"
        }]
      }],
      expression: "[\n                          'recommendedsort',\n                          {\n                            rules: [\n                              { required: false, message: '请输入Feed强推排序' },\n                              { pattern: /\\d$/, message: '请输入数字'}\n                              ],\n                              },\n                              ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxlength: "10"
    },
    on: {
      change: function change(e) {
        e.target.value = e.target.value.trim();
        return e;
      }
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#F5F5F5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };