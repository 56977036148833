var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoEssence:btn:add"],
      expression: "['infoEssence:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.showPopupForm();
      }
    }
  }, [_vm._v("创建")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: "id",
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(action, item) {
        return [_c("span", [_c("a", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["infoEssence:btn:edit"],
            expression: "['infoEssence:btn:edit']"
          }],
          on: {
            click: function click($event) {
              return _vm.handleEdit(item);
            }
          }
        }, [_vm._v("编辑")])]), _c("span", [_c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["infoEssence:btn:delete"],
            expression: "['infoEssence:btn:delete']"
          }],
          on: {
            click: function click($event) {
              return _vm.handleDelete(item);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DPopup", {
    attrs: {
      title: _vm.modalTitle,
      comVisible: _vm.addVersionVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("MediaForm", {
          attrs: {
            editId: _vm.editId,
            updateStatus: _vm.updateStatus
          },
          on: {
            handleAddCancle: _vm.handleAddCancle,
            getList: _vm.getList
          }
        })];
      },
      proxy: true
    }])
  }), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      loading: _vm.loading,
      isDelete: false,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.cancel,
      submit: _vm.handleSubmit
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };